(function($, Drupal) {
  Drupal.behaviors.ada = {
    attach: function() {
      var linksToNavigate = 'a, input, select, textarea, button, iframe, object, embed, *[tabindex], *[contenteditable]';
      var tabbableElements = 'a:tabbable, input:tabbable, button:tabbable select:tabbable, textarea:tabbable, iframe:tabbable, object:tabbable, embed:tabbable, *[tabindex]:tabbable';
      var noTabbableElements = '[tabindex=-1], [disabled], [aria-hidden=true]';
      var timer;

      $(document).on('mousemove', _.debounce(function() {
        // If mouse movement is detected then remove ada class.
        if ($('html').hasClass('ada-enabled')) {
          $('html').removeClass('ada-enabled');
        }
      }, 200));

      $(window).on('keydown', function(event) {
        var key = event.key;
        if (!$('html').hasClass('ada-enabled') && ['Tab', 'ArrowLeft', 'ArrowRight', 'ArrowDown', 'ArrowUp'].indexOf(key) > -1) {
          $('html').addClass('ada-enabled');
        }
      });

      $(linksToNavigate).not(noTabbableElements).on('keydown', function(event) {
        var $this = $(this);
        var key = event.key;
        if (['Enter', 'Escape', 'ArrowLeft', 'ArrowRight', 'ArrowDown', 'ArrowUp'].indexOf(key) === -1) {
          return;
        }

        var $tabbableElements = $(tabbableElements).not(noTabbableElements);
        var index = $tabbableElements.index($this);

        if (key === 'ArrowLeft' || key === 'ArrowUp') {
          if (index === 0) {
            focusElement(-1);
          } else {
            focusElement(index - 1);
          }
        }

        if (key === 'ArrowRight' || key === 'ArrowDown') {
          if (index === $tabbableElements.length - 1) {
            // If the element is the last then focus the first element.
            focusElement(0);
          } else {
            focusElement(index + 1);
          }
        }

        if (key === 'Enter') {
          if (timer) {
            clearTimeout(timer);
          }
          if ($this.hasClass('close-popup')) {
            // Focus previous element after a time in case it takes time to load
            focusTimer(index - 1);
          }
          // If the link/label has pop up then click to open
          if ($this[0].hasAttribute('aria-haspopup')) {
            // Focus next element after a time in case it takes time to load
            focusTimer(index + 1);
          }
        }

        if (key === 'Escape') {
          focusElement(index - 1);
        }
      });

      function focusTimer(index) {
        timer = setTimeout(function() {
          focusElement(index);
        }, 250);
      }

      function focusElement(index) {
        $(tabbableElements).not(noTabbableElements).eq(index, 10).focus();
      }
    }
  };
})(jQuery, Drupal);
